<template>
    <div class='d-flex align-items-center h-100'>
        <div id='stap-inner-momenten' class='stap-inner' v-if="voorkeursmomenten && voorkeursmomenten.length > 0">
          <h1><span class='stap-number'>4.</span> Voorkeursmomenten</h1>
           <h5 class='stap-beschrijving'>Selecteer jouw voorkeur voor een dag(deel).</h5>
            <div class='row mt-5'>
              <div class="col-12"><h5>Eerste voorkeur</h5></div>
                <div class='voorkeuren-container'>
                    <div
                      v-for="voorkeursmoment in voorkeursmomenten" :key="voorkeursmoment.dagDagdeelId"
                      @click="setVoorkeur($event, voorkeursmoment, 0)"
                      class="card-option"
                      :class="isNotSelected(voorkeursmoment.dagDagdeelId, 0)"
                    >
                      {{voorkeursmoment.dagDagdeel.dag.description}} {{voorkeursmoment.dagDagdeel.dagdeel.periode}}
                    </div>
                </div>

              <div class="col-12 mt-4"><h5>Tweede voorkeur</h5></div>
              <div class='voorkeuren-container'>
                    <div
                      v-for="voorkeursmoment in voorkeursmomenten" :key="voorkeursmoment.dagDagdeelId"
                      @click="setVoorkeur($event, voorkeursmoment, 1)"
                      class="card-option"
                      :class="isNotSelected(voorkeursmoment.dagDagdeelId, 1)"
                    >
                      {{voorkeursmoment.dagDagdeel.dag.description}} {{voorkeursmoment.dagDagdeel.dagdeel.periode}}
                    </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
export default ({
  name: 'StapMomenten',
  methods: {
    setVoorkeur: function (e, dag, prio) {
      this.loading = true
      this.$store.dispatch('aanmelding/setVoorkeur', {
        dag: dag,
        prio: prio
      })
      if (this.fullfill) {
        this.$router.push({ name: 'StapErvaring' })
      }
      this.loading = false
    },
    isNotSelected: function (dagdeelid, prio) {
      if (
        this.$store.state.aanmelding.voorkeur[prio] &&
        this.$store.state.aanmelding.voorkeur[prio].dag.dagDagdeelId === dagdeelid
      ) {
        return 'selected'
      } else {
        return 'not-selected'
      }
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    this.$store.dispatch('options/getMomenten', this.$store.state.aanmelding.cursus.id)
  },
  computed: {
    fullfill () {
      return this.$store.state.aanmelding.voorkeur[0] &&
        this.$store.state.aanmelding.voorkeur[1]
    },
    voorkeursmomenten () {
      return this.$store.state.options.momenten
    }
  }
})
</script>

<style>
   .not-selected{
    opacity: .6;
  }
  .voorkeuren-container .card-option{
    margin: 0;
    font-size: 1rem;
  }
  .voorkeuren-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }
  .voorkeuren-container::first-letter{
    text-transform: uppercase;
  }
  #stap-inner-momenten{
    padding-bottom: 100px;
  }
  @media screen and (max-width: 768px) {
    .voorkeuren-container{
      grid-template-columns: 1fr 1fr;
    }
  }
</style>
